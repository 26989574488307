import * as THREE from 'three'
import { Suspense, useRef, useState, useEffect } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Preload, ScrollControls, Scroll, useScroll, Image, useFBO, PerspectiveCamera } from '@react-three/drei'
import { isMobile } from 'react-device-detect';

/*
const hoveredCursor =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNjQiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBjbGlwLXBhdGg9InVybCgjY2xpcDApIj48Y2lyY2xlIGN4PSIzMiIgY3k9IjMyIiByPSIyNi41IiBmaWxsPSJibGFjayIgc3Ryb2tlPSJibGFjayIvPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMzIgMzJMMzIgNDVIMzNMMzMgMzJINDVWMzFIMzNWMTlIMzJWMzFIMTlWMzJIMzJaIiBmaWxsPSJ3aGl0ZSIvPjxwYXRoIGQ9Ik0xLjk2MjMxIDEuOTYyMzFMMTMuNzAzMyA1LjEwODI5TDUuMTA4MjkgMTMuNzAzM0wxLjk2MjMxIDEuOTYyMzFaIiBmaWxsPSJibGFjayIvPjwvZz48ZGVmcz48Y2xpcFBhdGggaWQ9ImNsaXAwIj48cmVjdCB3aWR0aD0iNjQiIGhlaWdodD0iNjQiIGZpbGw9IndoaXRlIi8+PC9jbGlwUGF0aD48L2RlZnM+PC9zdmc+'
const defaultCursor =
  'data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNjQiIGhlaWdodD0iNjQiIHZpZXdCb3g9IjAgMCA2NCA2NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBjbGlwLXBhdGg9InVybCgjY2xpcDApIj48Y2lyY2xlIGN4PSIzMiIgY3k9IjMyIiByPSIyNi41IiBzdHJva2U9ImJsYWNrIi8+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0zMiAzMkw0MS4xOTI0IDQxLjE5MjRMNDEuODk5NSA0MC40ODUzTDMyLjcwNzEgMzEuMjkyOUw0MS4xOTI0IDIyLjgwNzZMNDAuNDg1MyAyMi4xMDA1TDMyIDMwLjU4NThMMjMuNTE0NyAyMi4xMDA1TDIyLjgwNzYgMjIuODA3NkwzMS4yOTI5IDMxLjI5MjlMMjIuMTAwNSA0MC40ODUzTDIyLjgwNzYgNDEuMTkyNEwzMiAzMloiIGZpbGw9ImJsYWNrIi8+PHBhdGggZD0iTTUuMzY3MTEgMTIuNzM3M0wyLjY2OTQyIDIuNjY5NDJMMTIuNzM3MyA1LjM2NzExTDUuMzY3MTEgMTIuNzM3M1oiIHN0cm9rZT0iYmxhY2siLz48L2c+PGRlZnM+PGNsaXBQYXRoIGlkPSJjbGlwMCI+PHJlY3Qgd2lkdGg9IjY0IiBoZWlnaHQ9IjY0IiBmaWxsPSJ3aGl0ZSIvPjwvY2xpcFBhdGg+PC9kZWZzPjwvc3ZnPg=='
*/

function ImageRef(props) {
  const ref = useRef()
  const group = useRef()
  const data = useScroll()
  var grayScale = 0;

  /*
  const [hovered, set] = useState(false)

  useEffect(() => {
    if (hovered) {
      document.body.style.cursor = `url('${hoveredCursor}'), pointer`
      return () => (document.body.style.cursor = `url('${defaultCursor}'), auto`)
    }
  }, [hovered])
  */

  useFrame((state, delta) => {
    //ref.current.position.z = THREE.MathUtils.damp(ref.current.position.z, Math.max(0, data.delta * 50), 4, delta)
    grayScale = THREE.MathUtils.damp(ref.current.material.grayscale, Math.min(1, (data.delta * 1000)), 6, delta);
    ref.current.material.grayscale = grayScale
    //ref.current.position.z = THREE.MathUtils.lerp(ref.current.position.z, hovered ? 1.5 : 0.5, 0.075)
  })

  return (
    //<Image ref={ref} {...props} onPointerOver={(e) => set(true)} onPointerOut={() => set(false)}/>
    <Image ref={ref} {...props} />
  )
}

function Images() {

  const { width, height } = useThree((state) => state.viewport)

  const data = useScroll()
  const group = useRef()

  useFrame(() => {
    group.current.children[0].material.zoom = 1 + data.range(0, 1 / 3) / 3
    group.current.children[1].material.zoom = 1 + data.range(0, 1 / 3) / 3
    group.current.children[2].material.zoom = 1 + data.range(1.15 / 3, 1 / 3) / 3
    group.current.children[3].material.zoom = 1 + data.range(1.15 / 3, 1 / 3) / 2
    group.current.children[4].material.zoom = 1 + data.range(1.25 / 3, 1 / 3) / 1
    group.current.children[5].material.zoom = 1 + data.range(1.8 / 3, 1 / 3) / 3
    //group.current.children[5].material.grayscale = 1 - data.range(1.6 / 3, 1 / 3)
    group.current.children[6].material.zoom = 1 + (1 - data.range(2 / 3, 1 / 3)) / 3
  })

  if(isMobile) {
    /**
     * Mobile
     * 
     */
    return (
      <group ref={group}>
        <ImageRef position={[0, -2.5, 0]} scale={[5, height - 5, 1]} url="/assets/images/img-05@1x.jpg" />
        <ImageRef position={[1, -5, 1]} scale={3} url="/assets/images/img-07@1x.jpg" />
        <ImageRef position={[-2.3, -height, 2]} scale={[1, 3, 1]} url="/assets/images/img-08@1x.jpg" />
        <ImageRef position={[-0.3, -height, 3]} scale={[1, 1, 1]} url="/assets/images/img-09@1x.jpg" />
        <ImageRef position={[0.75, -height - 1, 3.5]} scale={1.5} url="/assets/images/img-06@1x.jpg" />
        <ImageRef position={[0, -height * 1.5, 2.5]} scale={[1.5, 3, 1]} url="/assets/images/img-12@1x.jpg" />
        <ImageRef position={[0, -height * 2 - height / 4, 0]} scale={[width, height / 2, 1]} url="/assets/images/img-02@1x.jpg" />
      </group>
    )
  } else {
    /**
     * Desktop
     * 
     */
    return (
      <group ref={group}>
        <ImageRef position={[1, 0.8, 0]} scale={[6, height - 0.5, 1]} url="/assets/images/img-05@1x.jpg" />
        <ImageRef position={[3.6, -3, 1]} scale={3} url="/assets/images/img-07@1x.jpg" />
        <ImageRef position={[-2.3, -height, 2]} scale={[1, 3, 1]} url="/assets/images/img-08@1x.jpg" />
        <ImageRef position={[-0.6, -height, 2.6]} scale={[1.15, 1, 1]} url="/assets/images/img-09@1x.jpg" />
        <ImageRef position={[0.75, -height, 3.5]} scale={1.0} url="/assets/images/img-06@1x.jpg" />
        <ImageRef position={[0, -height * 1.5, 2.5]} scale={[1.5, 3, 1]} url="/assets/images/img-12@1x.jpg" />
        <ImageRef position={[0, -height * 2 - height / 4, 0]} scale={[width, height / 2, 1]} url="/assets/images/img-02@1x.jpg" />
      </group>
    )
  }

}

export default function App() {
  
  document.addEventListener('contextmenu', event => event.preventDefault());

  return (
    <Canvas gl={{ antialias: false }} dpr={[1, 1.5]}>
      <Suspense fallback={null}>
        <ScrollControls damping={4} pages={3}>
          <Scroll>
            <Images />
          </Scroll>
          <Scroll html>
            <img className="ui-logotype" src="/assets/images/ui-whiteline-logotype.svg"/>
            <h1>
              Diseño y construcción de espacios arquitectónicos y de interiores.
              <span>
                Arq. Cinthia Cavazos
              </span>
              <i>San Pedro Garza Garcia, N.L. México.</i>
            </h1>
          </Scroll>
        </ScrollControls>
        <Preload />
      </Suspense>
    </Canvas>
  )
}


/*
            <h2 className="ui-h2-footer" style={{ position: 'absolute', top: '185vh', left: '0.5vw' }}>Arquitectura y espacios que inspiran.</h2>
            <h2 style={{ position: 'absolute', top: '60vh', left: '0.5em' }}>to</h2>
            <h2 style={{ position: 'absolute', top: '120vh', left: '60vw' }}>be</h2>
            <h2 style={{ position: 'absolute', top: '198.5vh', left: '0.5vw', fontSize: '40vw' }}>home</h2>
*/