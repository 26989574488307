import ReactDOM from 'react-dom'
import './styles.css'
import App from './App'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faPhone } from "@fortawesome/free-solid-svg-icons"
import { faFacebook, faInstagram, faWhatsapp } from "@fortawesome/free-brands-svg-icons"

function Overlay() {
  return (
    <div style={{ position: 'absolute', top: 0, left: 0, pointerEvents: 'none', width: '100%', height: '100%' }}>
      <ul className="ui-info">
        <li>
          <a href="https://www.facebook.com/whitelinearquitectura/" target="_blank">
            <FontAwesomeIcon icon={faFacebook} />
          </a>
        </li>
        <li>
          <a href="https://www.instagram.com/whitelinearquitectura" target="_blank">
            <FontAwesomeIcon icon={faInstagram} />
          </a>          
        </li>
        <li>
          <a href="mailto: contacto@cinthiacavazos.com" target="_blank">
            <FontAwesomeIcon icon={faEnvelope} />
          </a>
        </li>
        <li> 
          <a href="tel: 8115772868" target="_blank">
            <FontAwesomeIcon icon={faPhone} />
          </a>
        </li>
      </ul>
    </div>
  )
}

/*
  <a className="ui-whatsapp" href="https://wa.me/528115772868" target="_blank">
    <FontAwesomeIcon icon={faWhatsapp} />
  </a>
*/

ReactDOM.render(
  <>
    <App />
    <Overlay />
  </>,
  document.getElementById('root')
)